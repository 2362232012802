<template>
    <div class="signup layout">
        <div class="title">
            <svg width="167" height="40" viewBox="0 0 167 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M42.9868 15.7755H46.4012C47.403 15.7755 47.9141 16.3673 47.9141 17.1429C47.9141 17.9388 47.4234 18.4898 46.4012 18.4898H42.9868V15.7755ZM42.9868 10.6122H46.258C47.0554 10.6122 47.5052 11.102 47.5052 11.7347C47.5052 12.3673 47.0554 12.7959 46.2172 12.7959H42.9868V10.6122ZM39 7.34694V21.6327H46.3807C48.9977 21.6122 51.9214 20.6531 51.9214 17.449C51.9214 16.1633 51.2671 14.8367 49.9382 14.1224C51.1853 13.551 51.5125 12.4286 51.5125 11.3469C51.5125 8.61225 49.1817 7.34694 46.3194 7.34694H39Z"
                    fill="url(#paint0_linear_836_2538)" />
                <path d="M54.1143 7.34694V21.6327H65.0116V18.2041H58.1624V7.34694H54.1143Z"
                    fill="url(#paint1_linear_836_2538)" />
                <path
                    d="M81.742 14.4898C81.742 9.73469 78.4912 7 73.7888 7C69.1068 7 65.8356 9.79592 65.8356 14.4898C65.8356 19.2653 69.1068 21.9796 73.7888 21.9796C78.4912 21.9796 81.742 19.2653 81.742 14.4898ZM77.6938 14.4898C77.6938 16.8571 76.1604 18.5306 73.7888 18.5306C71.4989 18.5306 69.8838 16.8776 69.8838 14.4898C69.8838 12.1224 71.4785 10.449 73.7888 10.449C76.1604 10.449 77.6938 12.0408 77.6938 14.4898Z"
                    fill="url(#paint2_linear_836_2538)" />
                <path
                    d="M94.2707 17.1224C93.2485 18.1633 91.8582 18.5714 90.8973 18.5714C88.0758 18.5714 87.0945 16.4694 87.074 14.6122C87.0536 12.7551 88.1372 10.5102 90.8973 10.5102C91.8582 10.5102 92.9213 10.7755 93.9436 11.7551L96.4379 9.30612C94.8228 7.73469 92.9622 7.02041 90.8973 7.02041C85.5202 7.02041 83.0463 10.9184 83.0668 14.5918C83.0872 18.4694 85.2748 22 90.8973 22C93.0645 22 95.0272 21.3673 96.6424 19.7755L94.2707 17.1224Z"
                    fill="url(#paint3_linear_836_2538)" />
                <path
                    d="M110.955 7.34694H106.109C104.903 8.85714 103.574 10.5306 102.347 12.0408V7.34694H98.2991V21.6327H102.347V16.8776L106.436 21.6327H111.445V21.3878C109.462 19.1224 107.152 16.551 105.148 14.3469L110.955 7.59184V7.34694Z"
                    fill="url(#paint4_linear_836_2538)" />
                <path
                    d="M133.14 7.34694H128.724L127.007 12.2653C126.68 13.449 126.414 14.5918 126.209 15.6735L123.49 7.34694H120.689L118.052 15.6735C117.847 14.6122 117.643 13.449 117.295 12.3673L115.455 7.34694H111.039L116.375 21.6122H119.646L122.1 14.7755L124.635 21.6122H127.927L133.14 7.34694Z"
                    fill="url(#paint5_linear_836_2538)" />
                <path
                    d="M144.275 21.6327H148.609L142.21 7.34694H138.121L131.701 21.6327H135.994L136.935 19.2857H143.334L144.275 21.6327ZM141.964 15.898H138.305L140.145 11.3469L141.964 15.898Z"
                    fill="url(#paint6_linear_836_2538)" />
                <path
                    d="M163 21.3878L159.279 16.5714C161.692 15.5102 162.305 14.0204 162.305 12.2653C162.305 9.69388 160.403 7.34694 156.744 7.34694H149.752V21.6327H153.8V17H155.21L158.543 21.6327H163V21.3878ZM156.744 10.5714C157.807 10.5714 158.318 11.449 158.318 12.3469C158.318 13.2041 157.848 14 156.785 14H153.8V10.5714H156.744Z"
                    fill="url(#paint7_linear_836_2538)" />
                <path
                    d="M48.663 37.7151H51.1081L45.7765 25.2493H43.3315L38 37.7151H40.428L41.4468 35.3644H47.6613L48.663 37.7151ZM46.7613 33.174H42.3467L44.554 27.8671L46.7613 33.174Z"
                    fill="white" />
                <path
                    d="M60.7511 34.5274C59.9701 35.3466 58.7985 35.7918 57.7628 35.7918C54.8253 35.7918 53.6877 33.637 53.6708 31.5712C53.6538 29.4877 54.8933 27.2438 57.7628 27.2438C58.7985 27.2438 59.8512 27.6178 60.6323 28.4192L62.1265 26.9055C60.904 25.6411 59.3758 25 57.7628 25C53.467 25 51.4465 28.3123 51.4634 31.5712C51.4804 34.8123 53.3481 38 57.7628 38C59.4777 38 61.0568 37.4123 62.2793 36.1479L60.7511 34.5274Z"
                    fill="white" />
                <path
                    d="M72.3415 34.5274C71.5605 35.3466 70.3889 35.7918 69.3532 35.7918C66.4157 35.7918 65.2781 33.637 65.2612 31.5712C65.2442 29.4877 66.4837 27.2438 69.3532 27.2438C70.3889 27.2438 71.4416 27.6178 72.2227 28.4192L73.7169 26.9055C72.4944 25.6411 70.9662 25 69.3532 25C65.0574 25 63.0369 28.3123 63.0538 31.5712C63.0708 34.8123 64.9385 38 69.3532 38C71.0681 38 72.6472 37.4123 73.8697 36.1479L72.3415 34.5274Z"
                    fill="white" />
                <path
                    d="M84.5191 35.4H77.8462V32.5863H84.2813V30.3959H77.8462V27.5288H84.5191V25.2493H75.6219C75.6219 29.3986 75.6219 33.5658 75.6219 37.7151H84.5191V35.4Z"
                    fill="white" />
                <path d="M86.9138 25.2493V37.7151H95.2677V35.5425H89.1551V25.2493H86.9138Z" fill="white" />
                <path
                    d="M105.859 35.4H99.1864V32.5863H105.622V30.3959H99.1864V27.5288H105.859V25.2493H96.9621C96.9621 29.3986 96.9621 33.5658 96.9621 37.7151H105.859V35.4Z"
                    fill="white" />
                <path
                    d="M118.781 37.5548L115.182 33.2986C117.44 32.8178 118.187 31.0904 118.187 29.3808C118.187 27.226 116.71 25.2671 113.908 25.2493C112.023 25.2493 110.139 25.2315 108.254 25.2315V37.7151H110.495V33.5479H112.771L116.217 37.7151H118.781V37.5548ZM113.908 27.4397C115.317 27.4397 115.946 28.437 115.946 29.4342C115.946 30.4315 115.334 31.4288 113.908 31.4288H110.495V27.4397H113.908Z"
                    fill="white" />
                <path
                    d="M129.912 37.7151H132.357L127.025 25.2493H124.58L119.249 37.7151H121.677L122.696 35.3644H128.91L129.912 37.7151ZM128.01 33.174H123.596L125.803 27.8671L128.01 33.174Z"
                    fill="white" />
                <path
                    d="M135.427 37.7151H137.668V27.3863H141.437V25.2493C137.991 25.2493 135.087 25.2493 131.657 25.2493V27.3863H135.427V37.7151Z"
                    fill="white" />
                <path
                    d="M148.327 25C144.88 25 142.163 27.6356 142.163 31.5712C142.163 34.8479 144.099 38 148.276 38C152.453 38 154.338 34.7767 154.371 31.5534C154.405 28.3123 152.385 25.0356 148.327 25ZM144.371 31.6068C144.303 29.1315 145.797 27.137 148.327 27.137C151.061 27.1904 152.198 29.5055 152.164 31.589C152.13 33.637 151.077 35.8452 148.276 35.8452C145.474 35.8452 144.422 33.6548 144.371 31.6068Z"
                    fill="white" />
                <path
                    d="M167 37.5548L163.4 33.2986C165.659 32.8178 166.406 31.0904 166.406 29.3808C166.406 27.226 164.929 25.2671 162.127 25.2493C160.242 25.2493 158.358 25.2315 156.473 25.2315V37.7151H158.714V33.5479H160.989L164.436 37.7151H167V37.5548ZM162.127 27.4397C163.536 27.4397 164.164 28.437 164.164 29.4342C164.164 30.4315 163.553 31.4288 162.127 31.4288H158.714V27.4397H162.127Z"
                    fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M4.83275 11.7157L16.5 0L28.1673 11.7157C34.6109 18.1861 34.6109 28.6768 28.1673 35.1472C21.7236 41.6176 11.2764 41.6176 4.83275 35.1472C-1.61092 28.6768 -1.61092 18.1861 4.83275 11.7157ZM23.167 13.3894L24.8337 15.0631C29.4364 19.6848 29.4364 27.1781 24.8337 31.7998C22.1073 34.5377 18.3772 35.6536 14.8326 35.1478L21.8336 28.1177L21.8227 28.1068C24.2573 25.3175 24.1498 21.071 21.5003 18.4104L19.8335 16.7368L23.167 13.3894ZM9.99968 33.3061L13.451 29.8405C12.7454 29.502 12.0842 29.0393 11.4997 28.4525C8.7382 25.6794 8.7382 21.1835 11.4997 18.4104L19.8335 10.0421L16.5 6.6947L8.16626 15.0631C3.56362 19.6848 3.56362 27.1781 8.16626 31.7998C8.73704 32.373 9.35183 32.8751 9.99968 33.3061ZM18.1668 21.7578C19.0873 22.6821 19.0873 24.1808 18.1668 25.1051C17.2462 26.0295 15.7538 26.0295 14.8332 25.1051C13.9127 24.1808 13.9127 22.6821 14.8332 21.7578L16.5 20.0841L18.1668 21.7578Z"
                    fill="url(#paint8_linear_836_2538)" />
                <defs>
                    <linearGradient id="paint0_linear_836_2538" x1="38.9991" y1="21.9991" x2="114.235" y2="-46.3184"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FF8688" />
                        <stop offset="1" stop-color="#E9373E" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_836_2538" x1="38.9991" y1="21.9991" x2="114.235" y2="-46.3184"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FF8688" />
                        <stop offset="1" stop-color="#E9373E" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_836_2538" x1="38.9991" y1="21.9991" x2="114.235" y2="-46.3184"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FF8688" />
                        <stop offset="1" stop-color="#E9373E" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_836_2538" x1="38.9991" y1="21.9991" x2="114.235" y2="-46.3184"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FF8688" />
                        <stop offset="1" stop-color="#E9373E" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_836_2538" x1="38.9991" y1="21.9991" x2="114.235" y2="-46.3184"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FF8688" />
                        <stop offset="1" stop-color="#E9373E" />
                    </linearGradient>
                    <linearGradient id="paint5_linear_836_2538" x1="38.9991" y1="21.9991" x2="114.235" y2="-46.3184"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FF8688" />
                        <stop offset="1" stop-color="#E9373E" />
                    </linearGradient>
                    <linearGradient id="paint6_linear_836_2538" x1="38.9991" y1="21.9991" x2="114.235" y2="-46.3184"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FF8688" />
                        <stop offset="1" stop-color="#E9373E" />
                    </linearGradient>
                    <linearGradient id="paint7_linear_836_2538" x1="38.9991" y1="21.9991" x2="114.235" y2="-46.3184"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FF8688" />
                        <stop offset="1" stop-color="#E9373E" />
                    </linearGradient>
                    <linearGradient id="paint8_linear_836_2538" x1="3.41112" y1="12.9556" x2="0.856539" y2="34.099"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FF8688" />
                        <stop offset="1" stop-color="#E9373E" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
        <div class="box">
            <h1>Sign up</h1>
            <div class="form">
                <div>
                    <span class="label">Email</span>
                    <input type="text" v-model.trim="email">
                </div>
                <div>
                    <span class="label">Password</span>
                    <input type="password" v-model.trim="pw1">
                </div>
                <div>
                    <span class="label">Confirm Password</span>
                    <input type="password" v-model.trim="pw2">
                </div>
            </div>
            <div class="btnbox">
                <span class="btn btn_border" @click="toUser">log in</span>
                <span class="btn btn_red" @click="signup">sign up</span>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import {
        defineComponent,
        ref,
        computed
    } from "vue";
    // import {
    //     useStore
    // } from "vuex";
    import {
        useRouter
    } from "vue-router";
    import common from "../../utils/common";
    export default defineComponent({
        name: "",
        props: {},
        components: {},
        setup(props, ctx) {
            let router = useRouter();
            // let store = useStore();
            let email = ref('');
            let pw1 = ref('');
            let pw2 = ref('');
            //验证邮箱
            let checkMail = computed(()  => {
                const mailReg = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-zA-Z0-9]{2,9}[\.][a-z]{2,5}/g;
                return email.value && mailReg.test(email.value) && email.value.length <
                    31 && email.value
                    .length > 4;
            });
            //验证密码
            let checkPW = computed(() => {
                return pw1.value == pw2.value && pw1.value.length > 5 && pw1
                    .value.length <
                    13 &&
                    common.form.reg.specialCharREG.test(pw1.value)
            });
            const toUser = () => {
                router.push('/vpn/user');
            }
            const signup = () => {
                if (!checkMail.value) {
                    alert('error')
                }
                // console.log('--lb',common.form.reg.specialCharREG.test(pw1.value), pw1.value);
                // console.log('--lb',checkMail.value, checkPW.value);
                if (checkMail.value && checkPW.value) {
                    common.showLoading('vpn signup');
                    const sendUrl = common.userApi.requestUrl + '/vpn_api/accounts';
                    const sendHeaders = common.buildHeaders('form');
                    const sendData = common.buildSendData({
                        account: email.value,
                        password: pw1.value
                    });
                    axios.post(sendUrl, sendData, {
                        headers: sendHeaders
                    }).then((r) => {
                        console.log('--lb',r);
                        if (r.data.code == 0) {
                            toastr['success'](r.data.msg);
                            router.push('/vpn/user');
                        } else {
                            toastr['error'](r.data.msg);
                        }
                    }).catch((e) => {
                        console.log('--lb',e);
                        toastr['error'](e);
                    }).finally(() => {
                        common.hideLoading('vpn signup');
                    })
                }
            }
            return {
                signup,
                toUser,
                email,
                pw1,
                pw2
            };
        },
    });
</script>

<style lang='scss' scoped>

</style>